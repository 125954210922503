/* src/App.css */
.App {
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.map-image {
  width: 600px;  /* Ajusta el tamaño de la imagen */
  height: auto;
}

.annotation-point {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;  /* Cambia el color según el tipo de punto */
  border-radius: 50%;
  cursor: pointer;
}

.annotation-point.elevador {
  background-color: blue;
}

.annotation-point.rampa {
  background-color: green;
}

.annotation-point.puente {
  background-color: yellow;
}

.annotation-point.banqueta {
  background-color: orange;
}



/* Para la animación de los tooltips */
.tooltip-content {
  position: absolute;
  transform: translate(-50%, -100%); /* Para posicionar el tooltip arriba del punto */
  max-width: 150px; /* Limitar el tamaño en pantallas pequeñas */
  white-space: nowrap;
  z-index: 9999;
  padding: 8px;
  border-radius: 8px;
}

/* Para los puntos */
.elevador {
  background-color: blue;
}
.puente {
  background-color: green;
}
.rampa {
  background-color: yellow;
}
.banqueta {
  background-color: red;
}

/* Ajustes para el mapa en dispositivos móviles */
@media screen and (max-width: 768px) {
  .map-container {
    width: 100%;
    height: 50vh; /* Ajustar la altura para pantallas pequeñas */
  }

  .tooltip-content {
    font-size: 14px; /* Texto más pequeño */
  }

  .map {
    background-size: contain; /* Hacer que la imagen se ajuste mejor */
  }
}


/* Animación de rebote */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

/* Estilo del Tooltip */
.tooltip-content {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}




.tooltip {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.w-6:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
